const loadingControl = {
    state: {
        reqCount: 0,// 请求数量
    },

    mutations: {
        increment: (state) => {
            state.reqCount += 1;
        },
        decrement: (state) => {
            if (state.reqCount > 0) {
                state.reqCount -= 1;
            }
        },
    },

    actions: {},
};

export default loadingControl;
