import request from '@/api/request'
// import instance from '@/api/instance'
export function airlogin(data) {
  return request({
    // url: `/main/airport-travel/main/api/driver/account/login`,
    url: `/main/main/driver/account/login`,
    method: 'post',
    data
  })
}
//获取用户信息
export function getOpenCode(code) {
  return request({
    url: process.env.NODE_ENV=='development' ? `http://passenger-test.snchuxing.com/main/main/customer/account/oauth?code=`+code : `https://passenger.snchuxing.com/main/main/customer/account/oauth?code=`+code,
    method: 'get',
  })
}
//获取用户信息
export function wxlogin(param) {
  return request({
    url: process.env.NODE_ENV=='development' ? `http://passenger-test.snchuxing.com/main/main/customer/account/openId/userInfo?openCode=`+param : `https://passenger.snchuxing.com/main/main/customer/account/openId/userInfo?openCode=`+param,

    method: 'get',
  })
}
export function bindPhone(param) {
  return request({
    url: `http://passenger-test.snchuxing.com/main/main/customer/account/openId/userInfo?openCode=`+param,
    method: 'get',
  })
}
//获取验证码
export function smsCode(param) {
  return request({
    // url: `/main/main/captcha/mobile/bind`,
    url: `/main/main/captcha/customer/verificationCode`,
    method: 'get',
    params: param
  })
}
//手机绑定
// 微信绑定账号
export function wxbindPhone(data) {
  return request({
    url: `/main/main/customer/account/bind/account`,
    method: 'post',
    data
  })
}