import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import loadingControl from '@/store/modules/loadingControl';
import getters from "./getters";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // token: sessionStorage.getItem("codetoken"),
  },
  mutations: {
    // SET_TOKEN: (state, token) => {
    //   sessionStorage.setItem("codetoken", token);
    //   state.token = token;
    // },
  },
  actions: {
    // settoken(context, value) {
    //   console.log("SET_TOKEN", value);
    //   context.commit("SET_TOKEN", value);
    // },
  },
  modules: {
    user,
    loadingControl
  },
  getters,
});
