import Vue from 'vue';
import VueRouter from 'vue-router';
import { getOpenCode, wxlogin } from '@/api/login';
import store from '@/store';
import { setToken } from '@/utils/auth';
import utils from '@/utils/index';

// 路由跳转报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => err);
};

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch((err) => err);
};
// 路由路径设置
const routes = [
    {
        path: '/loginPhone',
        component: () => import('@/views/loginPhone.vue'),
        hidden: true,
        meta: {
            title: '手机绑定',
        },
    },
    // 首页空提示
    {
        path: '/',
        component: () => import('@/views/index.vue'),
        name: 'index',
        meta: {
            title: '首页',
        },
    },
    //停车场
    {
        path: '/parking',
        component: () => import('@/views/passenger/index.vue'),
        name: 'parking',
        meta: {
            title: '空港神鸟出行',
        },
    },
    // 订单
    {
        path: '/order',
        component: () => import('@/views/oderList/index.vue'),
        name: 'order',
        meta: {
            title: '订单',
        },
    },
    {
        path: '/parking',
        component: () => import('@/views/passenger/index.vue'),
        name: 'parking',
        meta: {
            title: '空港神鸟出行',
        },
    },
    {
        path: '/numberplate',
        component: () => import('@/views/passenger/numberplate.vue'),
        name: 'numberplate',
        meta: {
            title: '历史记录',
        },
    },
    {
        path: '/codeplate',
        component: () => import('@/views/passenger/codeplate.vue'),
        name: 'codeplate',
        meta: {
            title: '出场码',
        },
    },
    {
        path: '/newcodeplate',
        component: () => import('@/views/passenger/newcodeplate.vue'),
        name: 'newcodeplate',
        meta: {
            title: '出场码',
        },
    },
    {
        path: '/refresh',
        component: () => import('@/views/refresh.vue'),
        name: 'refresh',
        meta: {
            title: '刷新界面',
        },
    },
    // 收钱吧支付结果
    {
        path: '/sqb-pay-result',
        component: () =>
            import('@/views/pay/result.vue'),
        name: 'sqbPayResult',
        meta: {
            title: '支付结果',
        },
    },
    {
        path: '/sqb-pay-result-wt',
        component: () =>
          import('@/views/pay/result.vue'),
        name: 'sqbPayResultWt',
        meta: {
          title: '支付结果',
        },
    },
    // { path: "/", redirect: "/login", hidden: true },
    {path: '*', redirect: '/404', hidden: true},
];
// 创建路由对象
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
// 路由拦截器
let flagIndex = true;
router.beforeEach((to, from, next) => {
    if (flagIndex) {
        localStorage.setItem('path', JSON.stringify(to.path));
        flagIndex = false;
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    let userAgent = window.navigator.userAgent;
    if (
        (userAgent.includes('iPhone') || userAgent.includes('iPad')) &&
        !window.wechatUrl
    ) {
        window.wechatUrl = location.href;
    }
    let flag = JSON.parse(localStorage.getItem('isBindPhone'));
    // to 登陆、支付结果。 直接跳转
    if (to.path == '/loginPhone' || to.path.startsWith('/sqb-pay-result')) {
        next();
        return;
    }
    if (!flag) {
        //授权
        getCode();
        next(false);
        // next();
    } else {
        if (to.path == '/loginPhone' && flag) {
            next('/');
        } else {
            next();
        }
    }
});
// 获取微信授权回跳授权 code
async function getCode() {
    let code = '';
    // let href = window.location.href;
    // let encodeHref = encodeURIComponent(href);

    let path = JSON.parse(localStorage.getItem('path'));
    let encodeHref = '';
    console.log(path, 'pathpathpath');
    if (path == '/order') {
        encodeHref =
            process.env.NODE_ENV == 'development'
                ? encodeURIComponent('http://passenger-test.snchuxing.com/order')
                : encodeURIComponent('https://passenger.snchuxing.com/order');
    } else {
        encodeHref =
            process.env.NODE_ENV == 'development'
                ? encodeURIComponent('http://passenger-test.snchuxing.com/parking')
                : encodeURIComponent('https://passenger.snchuxing.com/parking');
    }
    console.log(encodeHref, 'encodeHref');
    //旧地址 测试
    var to_url =
        process.env.NODE_ENV == 'development'
            ? `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4d91edf20e00c333&redirect_uri=${encodeHref}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
            : `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd2a2faa7f258f4a9&redirect_uri=${encodeHref}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`;

    code = getUrlCode().code; // 截取code
    if (code == null || code === '') {
        // 如果没有code，则去请求
        window.location.href = to_url;
    } else {
        await getUserInfo(code);
    }
}

// 从url中获取code返回
function getUrlCode() {
    // 截取url中的code方法
    var url = location.search;
    var theRequest = new Object();
    if (url.indexOf('?') != -1) {
        var str = url.substr(1);
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1];
        }
    }
    return theRequest;
}
// 获取用户信息
async function getUserInfo(code) {
    let openCodeRes = await getOpenCode(code);
    let openCode = openCodeRes.data.result.openCode;
    store.state.user.openCode = openCode;
    let res = await wxlogin(openCode);
    // 是否绑定手机
    localStorage.setItem('isBindPhone', res.data.result.isBind);
    // alert('是否绑定',res.data.result.isBind)
    //保存 用户信息
    store.state.user.userInfo = res.data.result.wxLoginVO;
    localStorage.setItem('wxuserInfo', JSON.stringify(res.data.result.wxLoginVO));

    //判断是否绑定手机
    if (!res.data.result.isBind) {
        router.replace('/loginPhone');
    } else {
        //保存 openId
        localStorage.setItem('wx_openId', res.data.result.wxLoginVO.wechatOpenId);
        //设置 token;
        res.data.result.wxLoginVO && setToken(res.data.result.wxLoginVO.token);
        // 跳转首次进入页面
        if (utils.getFirstAccessPath()) {
            location.href = utils.getFirstAccessPath();
        } else {
            router.replace('/');
        }
    }
}

Vue.use(VueRouter);

export default router;
