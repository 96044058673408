import Vue from "vue";
// 在http.js中引入axios
import axios from "axios"; // 引入axios
import store from "@/store";
import QS from "qs"; // 引入qs模块，用来序列化post类型的数据，后面会提到
import router from "../router";
import { Toast, Dialog } from "vant";
// vant的toast提示框组件，大家可根据自己的ui组件更改。
import { getToken, removeToken } from "@/utils/auth";
import loadingControl from "@/store/modules/loadingControl";
const loadingS = Toast;
// 请求超时时间
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 50000, // request timeout
});
// post请求头
service.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    //   const token = store.state.token;
    // let token =  'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI4ZDNmZmRjOC00NDM3LTRhNDYtODI0MC1jYjk4NzJkOWIzODUiLCJzdWIiOiJVQzE1OTM2MDY4NjEzOTMwMTg4ODAiLCJpc3MiOiJhZG1pbiIsImlhdCI6MTY3ODUwNzM3MiwiZXhwIjoxNjgxMDk5MzcyfQ.14rSAs5f4P2mQJatgHKKS_QRpHxTW89aD9k0dMeeOUU'
    // let token = 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI3NTQ0NTFkZS1lZjIzLTRkYjctYjA1OS0xMjIyZGUwYTY5ZjgiLCJzdWIiOiJVQzE1OTI1MTk5NDg0OTYwNzY4MDAiLCJpc3MiOiJhZG1pbiIsImlhdCI6MTY3Nzk0MTIwMiwiZXhwIjoxNjc4NTQ2MDAyfQ.NWfRO5KaHXxaXXAXh-YEv-UPCLWkYp4WuUZKjNkzpfY'
    config.headers.Authorization = getToken();
    // config.headers.Authorization = token;
    // console.log( getToken(),' getToken()')
    //创建vant loading加载
    // 多个请求 loading 控制
    store.commit("increment");
    loadingS.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: "加载中...",
    });
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    store.commit("decrement");
    //清除加载效果
    if (store.getters.loadingCount === 0) {
      loadingS.clear();
    }
    // console.log(response,'response');
    // 如果登录信息报错 重新授权

    if (response.data.code == "A0101") {
      // let path = JSON.parse(localStorage.getItem("path"));
      localStorage.removeItem("isBindPhone");
      Dialog.alert({
        title: "提示",
        message: "登录信息已失效",
      }).then(() => {
        router.replace("/refresh");
      });
    }

    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    loadingS.clear(); //清除加载效果
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath },
          });
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          Toast({
            message: "登录过期，请重新登录",
            duration: 1000,
            forbidClick: true,
          });
          // 清除token
          removeToken();
          //   localStorage.removeItem('codetoken');
          store.commit("loginSuccess", null);
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath,
              },
            });
          }, 1000);
          break;
        // 404请求不存在
        case 404:
          Toast({
            message: "网络请求不存在",
            duration: 1000,
            forbidClick: true,
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Toast({
            message: error.response.data.message,
            duration: 1000,
            forbidClick: true,
          });
      }
      return Promise.reject(error.response);
    }
    Toast({
      message: error.message,
      duration: 1000,
      forbidClick: true,
    });
  }
);
export default service;
