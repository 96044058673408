<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
export default {
  name: "wxlogin",
  props: {},
  data() {
    return {
      code: "",
    };
  },
  methods: {},
  mounted() {},
    beforeDestroy() {
        this.$utils.removeFirstAccessPath();
    }
};
</script>
<style lang="less">
@import "assets/font.css";
@import "assets/css/global.scss";
#app {
  width: 100%;
  height: 100vh;
  overflow: auto;
  cursor: pointer;
  background: #f7f7f7;
}
</style>
