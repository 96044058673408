import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//微信
import wechat from '@/utils/wechat.js'
Object.assign(Vue.prototype, {
	'$wechat':wechat
})

//全局样式变量
import "@/assets/global.less";
//公共样式
import "@/assets/styles/common.less";
//引入Icon 库
import "@/assets/iconfont/iconfont.css";
//复制插件
import VueClipboard from "vue-clipboard2";
//限制点击次数
import preventClick from "@/utils/controlClickState";
Vue.use(preventClick);
//绑定手机号
import { getToken, setToken } from "@/utils/auth";
import {getOpenCode, wxlogin} from '@/api/login';
Vue.use(VueClipboard);
import "@/assets/icons";
import { createApp } from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
import "@/assets/styles/reset.css";
Vue.config.productionTip = false;
/* 路由发生变化修改页面title */

// 构建挂载
import utils from '@/utils/index'
Vue.prototype.$utils = utils;
// 获取首次进入时的 url path
// 授权 或 登录后跳转 首次访问页面
utils.setFirstAccessPath()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
