// 首次访问 path key
const firstAccessPathKey = 'firstAccessPath';
export default {
    // 首次进入访问的 path
    getFirstAccessPath() {
        return localStorage.getItem(firstAccessPathKey);
    },
    setFirstAccessPath() {
        if (!this.getFirstAccessPath()) {
            localStorage.setItem(firstAccessPathKey, location.href);
        }
    },
    removeFirstAccessPath() {
        localStorage.removeItem(firstAccessPathKey);
    },
};